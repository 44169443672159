import React from 'react'
import Layout404 from 'src/components/Layout404'
import LinkButton from 'src/components/LinkButton'
import { Box } from '@material-ui/core'
import styled, { createGlobalStyle } from 'styled-components'

const GlobalStyle = createGlobalStyle`
  body {
    background-color: ${props => props.theme.color.lightblue};
  }
`

const Heading = styled.h1`
  text-algin: center;
`

const BoxCenter = styled(Box)`
  height: 100vh;
  width: 100vw;
`

const NotFoundPage = () => (
  <Layout404>
    <GlobalStyle />
    <BoxCenter display="flex" justifyContent="center" alignItems="center">
      <Box>
        <Heading>
          PAGE NOT FOUND
        </Heading>
        <Box display="flex" justifyContent="center">
          <LinkButton to="/">
            Back Home
          </LinkButton>
        </Box>
      </Box>
    </BoxCenter>
  </Layout404>
)

export default NotFoundPage
