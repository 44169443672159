import React from 'react'
import PropTypes from 'prop-types'
import { Normalize } from 'styled-normalize'
import { ThemeProvider } from 'styled-components'

import theme from 'src/styles/theme'

const Layout = ({ children }) => (
  <ThemeProvider theme={theme}>
    <Normalize />
    <main>{children}</main>
  </ThemeProvider>
)

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
